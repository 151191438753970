export const faq_info = [
  {
    title: "faq_0_h",
    text: "faq_0_p",
  },
  {
    title: "faq_1_h",
    text: "faq_1_p",
  },
  {
    title: "faq_2_h",
    text: "faq_2_p",
  },
  {
    title: "faq_3_h",
    text: "faq_3_p",
  },
  {
    title: "faq_4_h",
    text: "faq_4_p",
  },
  {
    title: "faq_5_h",
    text: "faq_5_p",
  },
];
